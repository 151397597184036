<template>
  <div>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="5" xl="5">
        <Select
          title="Tipo"
          field="type"
          :formName="formName"
          :required="true"
          url="/api/v1/stock/type-product/select-all"
          :propsParams="{ any: 2 }"
          nameNewRegister="typeProductCreateUpdate"
          titleNewRegister="Tipo"
          :widthNewRegister="700"
          :heightNewRegister="250"
          v-model="accessory.typeProduct"
          :changed="cleanAccessory"
        >
          <TypeProductCreateUpdate
            :registerInSelect="true"
            v-model="accessory.typeProduct"
          />
        </Select>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="7" xl="7">
        <Select
          title="Acessório"
          field="accessory"
          :formName="formName"
          :required="true"
          url="/api/v1/stock/product/select-all"
          :propsParams="{ any: accessory.typeProduct.id }"
          :disabled="!accessory.typeProduct.id"
          nameNewRegister="typeProductCreateUpdate"
          titleNewRegister="Acessório"
          :widthNewRegister="700"
          :heightNewRegister="250"
          v-model="accessory.product"
        >
          <!--  <TypeProductCreateUpdate
            :registerInSelect="true"
            v-model="product.typeProduct"
          />-->
        </Select>
      </b-col>
    </b-row>
  <!--  <b-row>
      <b-col sm="12">
        <EditorHtml
          title="Descrição"
          field="description"
          :required="false"
          :maxLength="500"
          v-model="accessory.description"
        />
      </b-col>
    </b-row>-->
    <b-row>
      <b-col sm="2">
        <InputNumber
          title="Quantidade"
          field="quantity"
          :formName="formName"
          :required="false"
          :maxLength="5"
          type="float"
          v-model="accessory.quantity"
        />
      </b-col>
      <b-col sm="10">
        <div class="div-btn text-right">
          <Button
            _key="btnUpdateAccessory"
            type="success"
            title="Salvar"
            :disabled="!isFormValid(formName)"
            classIcon="fas fa-save"
            size="medium"
            :clicked="update"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import TypeProductCreateUpdate from "../type-product/TypeProductCreateUpdate.vue";

import Accessory from "@/components/modules/stock/accessory/Accessory.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "AccessoryUpdate",
  props: {
    productId: String,
    registerInSelect: Boolean,
    value: Object,
  },
  components: {
    InputNumber,
    Select,
    EditorHtml,
    Button,
    TypeProductCreateUpdate,
  },
  data() {
    return {
      formName: "accessoryUpdate",
      accessory: new Accessory(),
      urlUpdate: "/api/v1/stock/accessory/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["removeLoading", "removeEvent", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    update() {
      this.accessory.productId = this.productId;

      let params = { url: this.urlUpdate, obj: this.accessory };
      this.putApi(params).then((response) => {
        if (response.success && !this.registerInSelect) this.hideModal();
        this.removeLoading(["btnUpdateAccessory"]);
      });
    },
    cleanAccessory() {
      this.accessory.product = { id: "", content: "" };
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "accessoryUpdate") this.accessory.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-btn {
  margin-top: 35px;
}
</style>
