<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnPatrimonyCreate" title="Acessório" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Acessório(s)" :width="1200" :height="750" v-show="showModal('accessoryCreate')"
      :onHideModal="hideSearchProduct">
      <SearchProduct v-if="showSearchProduct" :types="'0,2,0,0,0'" :status="1" :showPrice="false"
        :addProduct="addAccessory" />
    </Modal>
    <Modal title="Acessório" :width="1000" :height="750" v-show="showModal('accessoryUpdate')">
      <AccessoryUpdate :productId="productId" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import SearchProduct from "../search-product/SearchProduct.vue";
import AccessoryUpdate from "./AccessoryUpdate.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "AccessoryList",
  components: {
    ViewTemplateConfiguration,
    Modal,
    Button,
    SearchProduct,
    AccessoryUpdate,
  },
  props: {
    productId: String,
  },
  data() {
    return {
      templateList: {
        urlCreate: "/api/v1/stock/accessory/create",
        urlGetApi: "/api/v1/stock/accessory/get-all",
        urlOrderAllApi: "/api/v1/stock/accessory/order-all",
        urlDeleteAllApi: "/api/v1/stock/accessory/delete",
        dragAndDrop: true,
        headerTable: [
          {
            field: "photo",
            container: "nixloc-photo-product",
            type: "image",
          },
          {
            field: "fullName",
            title: "Nome",
            type: "link",
            eventName: "accessoryUpdate",
            iconSearch: true,
            classCssBody: "center-vertical",
          },
          {
            field: "categoryProductName",
            title: "Categoria",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "manufacturerName",
            title: "Fabricante",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "quantity",
            title: "Quantidade",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
        ],
      },
      propsParam: {
        any: this.productId,
      },
      showSearchProduct: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    ...mapActions("generic", ["postApi"]),
    create() {
      this.openModal("accessoryCreate");
      this.addEvent({ name: "patrimonyCreate" });
      this.removeLoading(["btnPatrimonyCreate"]);
      this.showSearchProduct = true;
    },
    hideSearchProduct() {
      this.showSearchProduct = false;
    },
    addAccessory(product) {
      let obj = {
        id: "",
        order: 0,
        productMainId: this.productId,
        productId: product.id,
        productName: product.name,
        description: product.description,
        quantity: product.quantity,
      };
      let params = { url: this.templateList.urlCreate, obj: obj };
      this.postApi(params).then((response) => {
        this.removeLoading([product.id]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "accessoryUpdate") this.openModal("accessoryUpdate");
      },
      deep: true,
    },
  },
};
</script>
