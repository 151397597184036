export default class PriceTable {
    constructor() {
        this.id = "";
        this.productId = "";
        this.typePrice = { content: '', id: "" };
        this.quantity = 1;
        this.value = 0;
        this.cost = 0;

    }
    update(data) {
        this.id = data.id;
        this.productId = data.productId;
        this.typePrice = { id: data.typePrice.id, content: data.typePrice.content };
        this.quantity = data.quantity;
        this.value = data.value;
        this.cost = data.cost;
    }
}