export default class Accessory {
    constructor() {
        this.id = "";
        this.typeProduct = { id: "", content: "" };
        this.product = { id: "", content: "" };
        this.description = "";
        this.quantity = 0;
    }
    update(data) {
        this.id = data.id;
        this.typeProduct = { id: data.typeProduct.id, content: data.typeProduct.content };
        this.product = { id: data.product.id, content: data.product.content };
        this.description = data.description;
        this.quantity = data.quantity;
    }
}