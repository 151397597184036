<template>
  <div>
    <b-row>
      <b-col :sm="isCanAddPeriod ? '4' : '6'">
        <SelectStatic
          title="Tipo"
          fieldTarget="typePrice"
          :data="optionsWithByRules"
          :required="true"
          :markFormDirty="false"
          v-model="priceTable.typePrice"
        />
      </b-col>
      <b-col sm="2" v-if="isCanAddPeriod">
        <InputNumber
          title="Período"
          field="number"
          :formName="formName"
          :required="true"
          :maxLength="3"
          type="int"
          :markFormDirty="false"
          v-model="priceTable.quantity"
        />
      </b-col>
      <b-col sm="3">
        <InputDecimal
          title="Valor"
          field="number"
          :formName="formName"
          :required="true"
          :markFormDirty="false"
          v-model="priceTable.value"
        />
      </b-col>
      <b-col sm="3">
        <InputDecimal
          title="Custo"
          field="number"
          reference="blabla"
          :formName="formName"
          :required="false"
          :markFormDirty="false"
          v-model="priceTable.cost"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="10">
        <div class="div-checkbox text-right">
          <CheckboxSimple title="Salvar e novo" v-model="addOtherPeriod" />
        </div>
      </b-col>
      <b-col sm="2">
        <div class="text-right">
          <Button
            _key="btnSavePriceTable"
            type="success"
            title="Salvar"
            :disabled="!isFormValid(formName)"
            classIcon="fas fa-save"
            size="medium"
            :clicked="create"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import PriceTable from "@/components/modules/stock/price-table/PriceTable.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PriceTableCreateUpdate",
  props: {
    productId: String,
    registerInSelect: Boolean,
    value: Object,
  },
  components: {
    InputDecimal,
    InputNumber,
    SelectStatic,
    CheckboxSimple,
    Button,
  },
  data() {
    return {
      formName: "priceTableCreateUpdate",
      priceTable: new PriceTable(),
      urlCreate: "/api/v1/stock/price-table/create",
      urlUpdate: "/api/v1/stock/price-table/update",
      addOtherPeriod: false,
      options: [
        { content: "Minuto", id: "1" },
        { content: "Hora", id: "2" },
        { content: "Dia", id: "3" },
        { content: "Mês", id: "4" },
        { content: "Ano", id: "5" },
        { content: "Valor Único", id: "6" },
      ],
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapState("user", ["userLogged"]),
    optionsWithByRules() {
      let displayPeriod = this.userLogged.parameterRental.displayPeriod;
      let options = [];

      this.options.forEach((item) => {
        if (!displayPeriod.includes("1") && item.id == "1") return;
        if (!displayPeriod.includes("2") && item.id == "2") return;
        options.push(item);
      });

      return options;
    },
    isCanAddPeriod() {
      let typePrice = this.priceTable.typePrice.id;
      switch (typePrice) {
        case "1":
          return true;
        case "2":
          return true;
        case "3":
          return true;
        case "4":
          return true;
        case "5":
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    create() {
      this.priceTable.productId = this.productId;
      if (this.priceTable.id) {
        let params = { url: this.urlUpdate, obj: this.priceTable };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["btnSavePriceTable"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.priceTable };
        this.postApi(params).then((response) => {
          if (
            response.success &&
            !this.registerInSelect &&
            !this.addOtherPeriod
          ) {
            this.hideModal();
          }

          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.data.id,
              content: response.data.name,
            });
           this.hideVodal(this.formName);;
          }
          this.removeLoading(["btnSavePriceTable"]);
        });
      }
    },
    update(data) {
      this.priceTable = new PriceTable();
      this.priceTable.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "priceTableCreate") {
          this.resetValidation(this.formName);
          this.priceTable = new PriceTable();
        }
        if (event.name == "updatePriceTable") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-checkbox {
  padding-top: 8px;
}
</style>
