<template>
  <div>
    <ViewTemplateConfiguration
      :templateList="templateList"
      :showSearch="false"
      :showHorizontalFilter="false"
      :propsParam="propsParam"
    >
      <div slot="content-buttons">
        <Button
          _key="btnPriceTableCreate"
          title="Preço"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          :clicked="create"
        />
      </div>
    </ViewTemplateConfiguration>
    <Modal
      title="Preço"
      :width="800"
      :height="750"
      v-show="showModal('priceTableCreateUpdate')"
    >
      <PriceTableCreateUpdate :productId="productId" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import PriceTableCreateUpdate from "./PriceTableCreateUpdate.vue";

import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "PriceTableList",
  components: {
    ViewTemplateConfiguration,
    Modal,
    Button,
    PriceTableCreateUpdate,
  },
  props: {
    productId: String,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/stock/price-table/get-all",
        urlDeleteAllApi: "/api/v1/stock/price-table/delete",
        headerTable: [
          {
            field: "typePriceName",
            title: "Período",
            type: "link",
            eventName: "updatePriceTable",
          },
          {
            field: "value",
            title: "R$ Valor",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "text-center primary",
          },
          {
            field: "cost",
            title: "R$ Custo",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "text-center danger",
          },
        ],
      },
      propsParam: {
        any: this.productId,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("priceTableCreateUpdate");
      this.addEvent({ name: "priceTableCreate" });
      this.removeLoading(["btnPriceTableCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updatePriceTable") this.openModal("priceTableCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
