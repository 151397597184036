export default class Product {
    constructor() {
        this.id = "";
        this.codInternal = "";
        this.photo = "";
        this.type = 1;
        this.typeProduct = { id: "", content: "" };
        this.name = "";
        this.categoryProduct = { id: "", content: "" };
        this.manufacturer = { id: "", content: "" };
        this.status = 1;
        this.description = "";
        this.barCode = "";
        this.allowWithoutPatrimony = false;
        this.purchaseDate = "";
        this.replacementValue = 0;
        this.percentageInsurance = 0;
        this.calculateInsurance = 1;
        this.unitOfMeasurement = { id: "", content: "" };
        this.ncm = "";
        this.codigoBeneficioFiscal = "";
        this.totalStock = 0;

    }
    update(data) {
        this.id = data.id;
        this.codInternal = data.codInternal;
        this.photo = data.photo;
        this.type = data.type;

        this.typeProduct = { id: data.typeProduct.id, content: data.typeProduct.name };
        this.name = data.name;

        if (data.categoryProduct)
            this.categoryProduct = { id: data.categoryProduct.id, content: data.categoryProduct.name };

        if (data.manufacturer)
            this.manufacturer = { id: data.manufacturer.id, content: data.manufacturer.name };

        this.status = data.status;
        this.description = data.description;
        this.barCode = data.barCode;
        this.allowWithoutPatrimony = data.allowWithoutPatrimony;
        this.purchaseDate = data.purchaseDate;
        this.replacementValue = data.replacementValue;
        this.percentageInsurance = data.percentageInsurance;
        this.calculateInsurance = data.calculateInsurance;

        if (data.unitOfMeasurement)
            this.unitOfMeasurement = { id: data.unitOfMeasurement.id, content: data.unitOfMeasurement.name };

        this.codigoBeneficioFiscal = data.codigoBeneficioFiscal;
        this.ncm = data.ncm;
        this.totalStock = data.totalStock;
    }
}