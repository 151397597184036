export default class Patrimony {
    constructor() {
        this.id = "";
        this.barCode = "";
        this.serialNumber = "";
        this.purchaseDate = "";
        this.productId = "";
        this.status = 0;
        this.statusName = "";
    }
    update(data) {
        this.id = data.id;
        this.barCode = data.barCode;
        this.serialNumber = data.serialNumber;
        this.purchaseDate = data.purchaseDate;
        this.status = data.status;
        this.statusName = data.statusName;
        this.productId = data.productId;
    }
}