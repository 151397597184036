<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-buttons">
        <Button v-if="id" _key="btnProductCreate" title="Produto" classIcon="fas fa-plus-circle" type="primary"
          size="small" eventName="productCreate" :clicked="create" />
      </div>
      <div slot="content-main">
        <ProductCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>
import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import ProductCreateUpdate from "../../../components/modules/stock/product/ProductCreateUpdate.vue";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ProductCreateUpdateView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    ProductCreateUpdate,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Estoque",
        title: "Produto",
        formName: "productCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "productSelectOption",
      });
      this.removeLoading(["btnProductCreate"]);
    },
  },
};
</script>
